import axios from "@/axios/globalaxios";

export const getAcidByclassID = data =>{ //查询大赛
    return axios({
        method: 'post',
        url: '/tradelab/transaction/getAcidByclassID',
        data: data
    })
}

export const openFundTransactionInfo = data =>{
    return axios({
        url: "/tradelab/openFund/openFundTransactionInfo",
        method: 'post',
        data:data
    })
}

export const listApprovaledFund = data =>{
    return axios({
        url: "/tradelab/openFund/listApprovaledFund",
        method: 'post',
        data:data
    })
}

export const openFundPurchase = data =>{
    return axios({
        url: "/tradelab/openFund/openFundPurchase",
        method: 'post',
        data:data
    })
}

export const listCapitalFlow = data =>{
    return axios({
        url: "/tradelab/openFund/listCapitalFlow",
        method: 'post',
        data:data
    })
}

export const listTraded = data =>{
    return axios({
        url: "/tradelab/openFund/listTraded",
        method: 'post',
        data:data
    })
}

export const cancelOrder = data =>{
    return axios({
        url: "/tradelab/openFund/cancelOrder",
        method: 'post',
        data:data
    })
}


export const listCapitalShares = data =>{
    return axios({
        url: "/tradelab/openFund/listCapitalShares",
        method: 'post',
        data:data
    })
}

export const openFundRedeemInfo = data =>{
    return axios({
        url: "/tradelab/openFund/openFundRedeemInfo",
        method: 'post',
        data:data
    })
}


export const openFundRedeem = data =>{
    return axios({
        url: "/tradelab/openFund/openFundRedeem",
        method: 'post',
        data:data
    })
}

export const insertDiscussionArea = data =>{
    return axios({
        url: "/tradelab/openFund/insertDiscussionArea",
        method: 'post',
        data:data
    })
}

export const listDiscussionArea = data =>{
    return axios({
        url: "/tradelab/openFund/listDiscussionArea",
        method: 'post',
        data:data
    })
}

export const getNetWorthData = data =>{
    return axios({
        url: "/tradelab/openFund/getNetWorthData",
        method: 'post',
        data:data
    })
}